<template>
    <div>
      <section class="center py-50 gap-20 col">
        <div class="w600 weight-300">Text describing the Cloud service as service offered by SecurCom/SecurMeet for all clients with possibility to become a affiliate and earn money or free service. (TEXT needs to be written – Pavol/Terry/John)</div>
        
     
      </section>
      <section class="center py-20 col gap-20 pb-50">
      <div class="mt-20 space-between w600 row gap-20 wrap rowGap">
        <a href="#" class="w600">
            https://cloud.securmeet.com/register 
        </a>

        </div>
      </section>
    </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
  export default {
    data() {
      return {
        active: null,
      };
    },
    methods: {
      formatCurrency(x) {
        x = Number(x).toFixed(2);
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return "$ " + parts.join(".");
      },
      back() {
        this.$router.go(-1);
      },
      async setProduct(val) {
        if (this.active == val) return (this.active = null);
        this.active = val;
        await this.$wait();
        this.$refs.next.onFocus();
      },
      setActive() {
        this.$store.commit("products/active", this.active);
      },
      sentense(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      next() {
        if (this.active == null) return;
        this.$store.commit("licenses/active", 2);
        let type = "";
        if (this.active == 0) type = "ONPREMISE";
        if (this.active == 1) type = "CLOUD";
        this.$store.commit("licenses/type", type);
      },
    },
    computed: {
      choice() {
        return [
          {
            name: "On-Premise",
          },
          {
            name: "Cloud",
          },
        ];
      },
      disable() {
        return typeof this.active !== "number";
      },
      ...mapState("products", ["products"]),
      ...mapState("user", ["firstName", "lastName", "tip"]),
    },
    watch: {
      rooms(val) {
        let items = JSON.parse(JSON.stringify(val));
        this.items = items;
        this.filterSearch();
      },
      search(val) {
        let items = JSON.parse(JSON.stringify(this.rooms));
        this.items = items;
        this.filterSearch();
      },
    },
    mounted() {
      this.$store.dispatch("products/getProducts");
    },
  };
  </script>
  
  <style lang="less" scoped>
  .grid {
    display: grid;
    grid-template-columns: 150px auto;
    grid-row-gap: 15px;
    max-width: 600px;
    width: 100%;
  }
  
  .item {
    width: 180px;
  }
  
  .product {
    height: 150px;
    width: 150px;
    aspect-ratio: 1/1;
    border-radius: 10%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #4098755e;
    filter: contrast(0);
    transition: 0.2s all ease;
  }
  .bold {
    font-weight: 600;
  }
  
  .activeProduct {
    background-color: #4098751f;
  }
  
  .product:hover {
    background-color: hsla(0, 0%, 100%, 0.1);
  }
  
  button:focus > .product {
    border: 1px solid #409875;
  }
  
  .active {
    filter: contrast(1);
    background-color: #4098751f;
  }
  
  .pic {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  
  .tick {
    position: absolute;
    top: -10px;
    right: -5px;
    height: 30px;
    width: 30px;
    background-color: #4098755e;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .discount {
    position: absolute;
    top: -10px;
    left: 10px;
    height: 30px;
    width: 50px;
    background-color: #409875;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }
  
  .tickNonActive {
    background-color: #686868;
  }
  
  .camera {
    position: absolute;
    right: 0px;
    bottom: 4px;
    padding: 2px;
    transition: all 0.2s ease;
  }
  
  .camera:hover {
    transform: scale(1.1);
    opacity: 1;
  }
  
  .camera:focus {
    background-color: #ffffff;
    transform: scale(1.1);
    opacity: 1;
  }
  
  .disabled {
    pointer-events: none;
  }
  
  .notAllowed {
    cursor: not-allowed;
  }
  
  .rowGap {
    row-gap: 75px;
  }
  
  @media only screen and (max-width: 576px) {
    .rowGap {
      justify-content: center;
    }
  }
  </style>