<template>
  <div>
    <section class="center py-50 gap-20 col">
      <div class="w600 weight-300">
        To add a product license, you may do so by selecting 1 product from the
        {{ filter.length }} available. You will be able to confirm your order on
        the next page, after you have selected a product. Prices are displayed
        as a monthly charge.
      </div>
    </section>
    <section class="center pb-20 col">
      <div class="w600 weight-300 row gap-20 align-center">
        <span class=" weight-300">License duration</span>
        <s-dropdown v-model="select" :countries="durationOptions" />
      </div>
    </section>
    <section class="center py-20 col gap-20 pb-50">
      <div class="w600 weight-600">Products</div>

      <div class="mt-20 space-between w600 row gap-20 wrap rowGap">
        <button v-for="(product, index) in filter" :key="index" class="col align-center gap-20 item relative" @click="setProduct(index, product)">
          <transition name="fade">
            <div class="discount" :class="{ tickNonActive: active !== index }">
              {{ product.tierDiscount }} %
            </div>
          </transition>
          <div class="product w100 col" :class="{ active: active == index }">
            <div class="d-flex wrap center">
              <div class="center green--text font-18 nonwrap">
                <span class="weight-300">{{ product.name }}</span>
              </div>
              <transition name="fade">
                <div class="tick" v-if="active == index">
                  <s-icon width="22" color="green">check</s-icon>
                </div>
              </transition>
            </div>
          </div>
          <div :class="{ bold: active == index }" class="weight-300 col gap-10">
            <span> {{ formatCurrency(product.price) }} </span>
            <span class="font-14 weight-300 opacity-75">{{
                sentense(product.billingType)
            }}</span>
          </div>
        </button>
      </div>
    </section>
    <section class="center py-20 col gap-20">
      <div class="w600 justify-end">
        <s-btn :disabled="disable" ref="next" class="green" @click.native="next()">
          Next
        </s-btn>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      active: null,
      activeProduct: null,
      select: 'month'
    };
  },
  methods: {
    formatCurrency(x) {
      x = Number(x).toFixed(2);
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return "$ " + parts.join(".");
    },
    back() {
      this.$router.go(-1);
    },
    async setProduct(val, product) {
      if (this.active == val) return (this.active = null);
      this.active = val;
      this.activeProduct = product;
      await this.$wait();
      this.$refs.next.onFocus();
    },
    setActive() {
      this.$store.commit("products/active", this.activeProduct);
    },
    sentense(str) {
      return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
    },
    async next() {
      if (this.active == null) return;
      this.setActive();
      this.$store.commit("licenses/active", 3);
    },
  },
  computed: {
    durationOptions() {
      return [
        { name: 'Month', id: 'month', selected: true },
        { name: 'Annual', id: 'annual' }
      ]
    },
    disable() {
      return typeof this.active !== "number";
    },
    filter() {
      return this.products.filter((el) => el.productType == this.type);
    },
    ...mapState("products", ["products"]),
    ...mapState("user", ["firstName", "lastName", "tip"]),
    ...mapState("licenses", ["type"]),
  },
  watch: {
    rooms(val) {
      let items = JSON.parse(JSON.stringify(val));
      this.items = items;
      this.filterSearch();
    },
    select(val) {
      console.log(val)
    },
    search(val) {
      let items = JSON.parse(JSON.stringify(this.rooms));
      this.items = items;
      this.filterSearch();
    },
  },
  mounted() {
    this.$store.dispatch("products/getProducts");
  },
};
</script>

<style lang="less" scoped>
.grid {
  display: grid;
  grid-template-columns: 150px auto;
  grid-row-gap: 15px;
  max-width: 600px;
  width: 100%;
}

.item {
  width: 180px;
}

.product {
  height: 150px;
  width: 150px;
  aspect-ratio: 1/1;
  border-radius: 10%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #4098755e;
  filter: contrast(0);
  transition: 0.2s all ease;
}

.bold {
  font-weight: 600;
}

.activeProduct {
  background-color: #4098751f;
}

.product:hover {
  background-color: hsla(0, 0%, 100%, 0.1);
}

button:focus>.product {
  border: 1px solid #409875;
}

.active {
  filter: contrast(1);
  background-color: #4098751f;
}

.pic {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.tick {
  position: absolute;
  top: -10px;
  right: -5px;
  height: 30px;
  width: 30px;
  background-color: #4098755e;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.discount {
  position: absolute;
  top: -10px;
  left: 10px;
  height: 30px;
  width: 50px;
  background-color: #409875;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.tickNonActive {
  background-color: #686868;
}

.camera {
  position: absolute;
  right: 0px;
  bottom: 4px;
  padding: 2px;
  transition: all 0.2s ease;
}

.camera:hover {
  transform: scale(1.1);
  opacity: 1;
}

.camera:focus {
  background-color: #ffffff;
  transform: scale(1.1);
  opacity: 1;
}

.disabled {
  pointer-events: none;
}

.notAllowed {
  cursor: not-allowed;
}

.rowGap {
  row-gap: 75px;
}

@media only screen and (max-width: 576px) {
  .rowGap {
    justify-content: center;
  }
}
</style>