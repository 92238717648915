<template>
  <div class="page_container">
    <section class="row center">
      <div class="pt-35 pb-5 w600 row center space-between">
        <h1 class="my-0">Add license</h1>
        <button @click="back" class="row align-end gap-10 mb-2">
          <s-icon color="white" height="20">chevron-left</s-icon>
          <div class="my-0">Back</div>
        </button>
      </div>
    </section>
    <hr class="mt-15" />
    <section class="row center">
      <div class="row w600 align-center pa-20 mt-20 b-box bg">
        <button @click="jumpTo(1)" class="nowrap row align-center gap-10">
          <div class="circle" :class="{ 'inactive-bg': 1 > this.active }">
            <span v-if="1 >= this.active">1</span>
            <s-icon v-if="1 < this.active" height="14" color="white"
              >check</s-icon
            >
          </div>
          <span class="hide-sm" :class="{ 'inactive-text': 1 > this.active }"
            >System</span
          >
        </button>

        <hr class="grow hrr" />
        <button @click="jumpTo(2)" class="nowrap row align-center gap-10">
          <div class="circle" :class="{ 'inactive-bg': 2 > this.active }">
            <span v-if="2 >= this.active">2</span>
            <s-icon v-if="2 < this.active" height="14" color="white"
              >check</s-icon
            >
          </div>
          <span class="hide-sm" :class="{ 'inactive-text': 2 > this.active }"
            >Products</span
          >
        </button>
        <hr class="grow hrr" />
        <button @click="jumpTo(3)" class="nowrap row align-center gap-10">
          <div class="circle" :class="{ 'inactive-bg': 3 > this.active }">
            <span v-if="3 >= this.active">3</span>
            <s-icon v-if="3 < this.active" height="14" color="white"
              >check</s-icon
            >
          </div>
          <span class="hide-sm" :class="{ 'inactive-text': 3 > this.active }"
            >Confirmation</span
          >
        </button>
      </div>
    </section>

    <SystemType v-if="active == 1" />
    <AddLicense v-if="active == 2 && type == 'ONPREMISE'" />
    <CloudServices v-if="active == 2 && type == 'CLOUD'"/>
    <Confirmation v-if="active == 3" />
  </div>
</template>


<script>
import { mapState } from "vuex";
import SystemType from "./SystemType.vue";
import AddLicense from "./Add_License.vue";
import Confirmation from "./Confirmation.vue";
import CloudServices from "./CloudServices.vue";

export default {
  components: {
    SystemType,
    AddLicense,
    Confirmation,
    CloudServices,
  },
  data() {
    return {};
  },
  methods: {
    isActive(val) {
      if (val <= this.active) return true;
    },
    back() {
      if (this.active == 1) this.$router.push("/licenses");
      let newActive = this.active - 1;
      this.$store.commit("licenses/active", newActive);
    },
    jumpTo(val) {
      if (val >= this.active) return;
      this.$store.commit("licenses/active", val);
    },
  },
  computed: {
    ...mapState("licenses", ["active", "type"]),
  },
  watch: {},
  destroyed() {
    this.$store.commit("licenses/active", 1);
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.hrr {
  border: 1px solid #42515e;
  border-top: none;
}

.bg {
  background-color: #37424d;
}

.circle {
  min-width: 20px;
  min-height: 20px;
  background-color: var(--green);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  transition: all 0.3s ease;
}

.inactive-text {
  color: #7f7f7f;
}

.inactive-bg {
  background-color: #7f7f7f;
}

span {
  transition: all 0.3s ease;
}

@media only screen and (max-width: 576px) {
  .hide-sm {
    display: none;
  }

  .page_container {
    max-height: none;
  }
}
</style>