<template>
  <div>
    <section class="center py-50 gap-20 col">
      <div class="w600 weight-300">
        After confirmation, an Invoice will be generated which can be viewed in
        the billing section.
        <p>
          The license for {{ selected.name }} will be added to your licenses
          which after must be activated to use the product.
        </p>
        <p>
          You will receive an email that outlines next steps for on-premise
          server installation and on-boarding.
        </p>
      </div>
      <div class="row w600">
        <div class="weight-600">Confirmation</div>
      </div>
    </section>
    <section class="center row min-54">
      <div class="row"></div>
      <div class="w600 row">
        <img class="logo" src="/img/static/logo.svg" alt="" />
        <div class="center green--text font-24 nonwrap ml-10">
          <span>{{ selected.name }}</span>
        </div>
      </div>
    </section>
    <section class="center py-50 gap-20 col">
      <div class="w600 weight-300 col gap-10">
        <div class="row gap-5 wrap">
          Confirmation for
          <div class="weight-600">{{ selected.name }}</div>
          license.
        </div>
        <div class="row gap-5 wrap">
          <div>A</div>
          <div class="weight-600">
            {{ sentense(selected.billingType) }}
          </div>
          <div>charge of</div>
          <div class="weight-600">{{ formatCurrency(selected.price) }} USD</div>
          <div>will occur.</div>
        </div>
        <span class="row gap-5 wrap">
          <span v-if="!selected.active">
            License for this product is currently
          </span>
          <span class="weight-600">Currently inactive.</span>
        </span>
        <span class="row gap-5 wrap">
          <span>License will be activated upon purchase.</span>
        </span>
      </div>
    </section>
    <section class="center pb-50 gap-20 col">
      <div class="w600 weight-300">{{ selected.notes }}</div>
    </section>
    <section class="center py-20 col gap-20">
      <div class="w600 justify-end">
        <router-link to="/licenses" @click.native="confirm">
          <s-btn class="green">Confirm</s-btn></router-link
        >
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  methods: {
    formatCurrency(x) {
      x = Number(x).toFixed(2);
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return "$ " + parts.join(".");
    },
    sentense(str) {
      return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
    },
    confirm() {
      let data = { product: this.selected.id };
      this.$store.dispatch("licenses/postLicense", data);
    },
  },
  computed: {
    selected() {
      return this.active;
    },
    ...mapState("products", ["products", "active"]),
  },
};
</script>

<style scoped>
a:hover,
a:focus {
  font-weight: 600;
  outline: none;
}

.logo {
  max-width: 50px;
  object-fit: contain;
}

.min-54 {
  min-height: 54px;
}
</style>